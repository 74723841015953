import React, { useState } from "react";

export const Paginado = ({
    elementsPerPage,
    cantTotalElements,
    pagination,
    actualPage,
}) => {
    const number = [];
    const [page, setPage] = useState(actualPage || 1);

    if (cantTotalElements) {
        for (
            let i = 1;
            i <= Math.ceil(cantTotalElements / elementsPerPage);
            i++
        ) {
            number.push(i);
        }
    }

    if (cantTotalElements > elementsPerPage) {
        return (
            <nav className="flex p-2 mx-auto select-none mb-9 xs:mb-0">
                <div className="max-w-2xl mb-4 mr-3 cursor-pointer">
                    <ul className="inline-flex ">
                        <li
                            key={`Anterior${page}`}
                            onClick={() => {
                                if (page > 1) {
                                    pagination(page > 1 ? page - 1 : page);
                                    setPage(page > 1 ? page - 1 : page);
                                }
                            }}
                            className="flex items-center hover:bg-slate-100">
                            <svg
                                className="h-8 w-8 p-0.5 text-blue-bluecorner border border-blue-bluecorner mx-1 rounded-lg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                        </li>
                        {number?.map((numbers) => (
                            <li
                                className={
                                    page + 2 < numbers || page - 2 > numbers
                                        ? "hidden"
                                        : `px-3 text-lg border rounded-lg mx-1 ${
                                              numbers === page
                                                  ? ` bg-blue-bluecorner text-white font-semibold border-blue-bluecorner hover:border-gray-200`
                                                  : ` bg-white text-blue-bluecorner hover:bg-slate-100 font-semibold border-blue-bluecorner`
                                          }`
                                }
                                key={numbers}
                                onClick={() => {
                                    if (numbers !== page) {
                                        pagination(numbers);
                                        setPage(numbers);
                                    }
                                }}>
                                <span>{numbers} </span>
                            </li>
                        ))}
                        <li
                            onClick={() => {
                                if (page < number.at(-1)) {
                                    pagination(
                                        page < number.length ? page + 1 : page
                                    );
                                    setPage(
                                        page < number.length ? page + 1 : page
                                    );
                                }
                            }}
                            key={`Siguiente${page}`}
                            className="flex items-center hover:bg-slate-100">
                            <svg
                                className="h-8 w-8 p-0.5 text-blue-bluecorner border rounded-lg border-blue-bluecorner mx-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <polyline points="9 18 15 12 9 6" />
                            </svg>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    } else return null;
};
