import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ModalCalendarBoats from "./ModalCalendarBoats";
import Spinner from "../../../Spinner";
import VariablePrices from "../../onboarding 5/VariablePrices";
import DaysAvailableBoats from "../../onboarding 5/DaysAvailableBoats";

// ];

const PriceCalendar = ({
    setPage,
    producto,
    onboarding,
    setProductsAll,
    setOnboarding,
    setModalCalendario,
    tipo,
    setRegresar,
}) => {
    const dispatch = useDispatch();
    const [duracionSelect, setDuracionSelect] = useState("");
    const [duraciones, setDuraciones] = useState([]);
    const [createVisible, setCreateVisible] = useState(false);
    const [etiquetas, setEtiquetas] = useState(false);
    const [siguiente, setSiguiente] = useState("Siguiente");
    const [modalAvanzado, setModalAvanzado] = useState(false);
    const [arrayFechas, setArrayFechas] = useState([]);
    const [arrayDias, setArrayDias] = useState([]);
    const [arrayMeses, setArrayMeses] = useState([]);
    const [fechas, setFechas] = useState("");
    const [modal, setModal] = useState(false);
    const [valorDia, setValorDia] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [modalHabilitado, setModalHabilitado] = useState(false);
    const [modalDisponibilidad, setModalDisponibilidad] = useState(false);

    let meses = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
    };
    let mesesNoDisponibles = {
        Jan: "0",
        Feb: "1",
        Mar: "2",
        Apr: "3",
        May: "4",
        Jun: "5",
        Jul: "6",
        Aug: "7",
        Sep: "8",
        Oct: "9",
        Nov: "10",
        Dec: "11",
    };

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    console.log(onboarding);
    useEffect(() => {
        if (duracionSelect && duracionSelect !== "") {
            // setSpinner(false);
            const getPreciosDuracion = async () => {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/all/${onboarding.id}/${duracionSelect.id_duracion}`,
                    { headers }
                );

                setArrayDias(data.arrayDias);
                setArrayFechas(data.arrayFechas);
                setArrayMeses(data.arrayMeses);
                setEtiquetas(true);
            };
            getPreciosDuracion();
        }
    }, [duracionSelect, modal]);

    useEffect(() => {
        if (onboarding.tipo_producto === "casa") {
            const func = async () => {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/productoPreciosVariables/all/${onboarding.id}`,
                    { headers }
                );

                setArrayDias(data.arrayDias);
                setArrayFechas(data.arrayFechas);
                setArrayMeses(data.arrayMeses);
            };
            func();
        }
        if (onboarding.tipo_producto === "embarcacion") {
            const getduraciones = async () => {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/embarcacionPreciosVariables/getDuracion/${onboarding.id}`,
                    { headers }
                );

                if (duracionSelect === "") {
                    setDuraciones(data);
                    setDuracionSelect(data[0]);
                }
            };

            getduraciones();
        }
    }, [modal]);

    let dia = new Date();

    let conteo = [];
    let numero = 0;

    setTimeout(() => {
        setSpinner(true);
    }, 800);

    for (let i = 0; i < 365; i++) {
        dia.setDate(dia.getDate() + numero);
        numero = 1;

        let diaSemana = dia.getDay();
        let mes = dia.toDateString().substring(4, 7);
        let diaMes = dia.toDateString().substring(8, 10);
        let ano = dia.toDateString().substring(11, 15);
        let fecha = ano + "-" + meses[mes] + "-" + diaMes;

        let arrayEncontradoFecha = arrayFechas?.find(
            (element) => element.date.substring(0, 10) === fecha
        );
        let disponibilidadArrayFecha =
            onboarding.disponibilidad.arrayFechas?.find((f) => f === fecha);

        let arrayEncontradoDia = arrayDias?.find(
            (element) => element.dia === diaSemana
        );

        let disponibilidadArrayDias = onboarding.disponibilidad?.arrayDias.find(
            (dia) => Number(dia) === Number(diaSemana)
        );

        let arrayEncontradoMes = arrayMeses?.find(
            (element) => element.mes === meses[mes]
        );
        let disponibilidadArrayMes = onboarding.disponibilidad.arrayMeses?.find(
            (m) => `${m}` === mesesNoDisponibles[mes]
        );

        let pedidos =
            onboarding.tipo_producto === "casa"
                ? onboarding?.disponibilidad?.pedidos?.find(
                      (pedido) => pedido === fecha
                  )
                : duracionSelect
                ? onboarding?.disponibilidad?.pedidos[
                      duracionSelect.id_duracion
                  ]?.find((pedido) => pedido.fecha === fecha)
                : "no tiene turnos";

        if (pedidos === "no tiene turnos") {
        } else if (pedidos) {
            conteo.push({
                title: "Reservado",
                date: fecha,
                display: "background",
            });
        } else if (disponibilidadArrayMes || disponibilidadArrayMes === 0) {
            conteo.push({
                date: fecha,
                title: "No disponible",
                display: "background",
            });
        } else if (disponibilidadArrayFecha) {
            conteo.push({
                date: fecha,
                title: "No disponible",
                display: "background",
            });
        } else if (disponibilidadArrayDias || disponibilidadArrayDias === 0) {
            conteo.push({
                date: fecha,
                title: "No disponible",
                display: "background",
            });
        } else if (arrayEncontradoFecha) {
            conteo.push({
                title:
                    onboarding.tipo_producto === "embarcacion"
                        ? onboarding.tipo_moneda +
                          " " +
                          arrayEncontradoFecha.title
                        : onboarding.moneda_precio_base +
                          " " +
                          arrayEncontradoFecha.title,
                date: fecha,
                description: [fecha, "fecha"],
                color: "#48C9B0",
            });
        } else if (arrayEncontradoDia) {
            conteo.push({
                title:
                    onboarding.tipo_producto === "embarcacion"
                        ? onboarding.tipo_moneda +
                          " " +
                          arrayEncontradoDia.title
                        : onboarding.moneda_precio_base +
                          " " +
                          arrayEncontradoDia.title,
                date: fecha,
                description: [fecha, "dia"],
                color: "#DC7633 ",
            });
        } else if (arrayEncontradoMes) {
            conteo.push({
                title:
                    onboarding.tipo_producto === "embarcacion"
                        ? onboarding.tipo_moneda +
                          " " +
                          arrayEncontradoMes.title
                        : onboarding.moneda_precio_base +
                          " " +
                          arrayEncontradoMes.title,
                date: fecha,
                description: [fecha, "mes"],
                color: "#9B59B6",
            });
        } else {
            conteo.push({
                title:
                    onboarding.tipo_producto === "embarcacion"
                        ? onboarding.tipo_moneda +
                          " " +
                          duracionSelect.precio_base
                        : onboarding.moneda_precio_base +
                          " " +
                          onboarding.precio_base,
                date: fecha,
                description: [fecha, "normal"],
                // color: "#5499C7",
            });
        }
    }
    // conteo.map((element) => (element.color = "#1a8cff"));

    const handleDateClick = (arg) => {
        let valor = conteo?.find((element) => element.date === arg.dateStr);

        if (valor) {
            if (
                valor.title !== "Reservado" &&
                valor.title !== "No disponible"
            ) {
                // setSpinner(false);

                setValorDia(valor.title.split(" ")[1]);
                setFechas(arg.dateStr);
                setModal(true);
            }
        }
    };

    const handleEventClick = (arg) => {
        if (arg.title !== "Reservado" && arg.title !== "No disponible") {
            // setSpinner(false);
            setValorDia(arg.title.split(" ")[1]);

            setFechas(arg.extendedProps.description[0]);
            setModal(true);
        }
    };

    return (
        <>
            <div
                className={
                    duracionSelect === undefined &&
                    onboarding.tipo_producto === "embarcacion"
                        ? " text-center flex justify-center items-center"
                        : "hidden"
                }>
                <p>
                    Aun no tienes turnos, debe agregar por lo menos un turno con
                    su precio para poder ver el calendario
                </p>
            </div>
            <div
                className={
                    duracionSelect === undefined &&
                    onboarding.tipo_producto === "embarcacion"
                        ? "hidden"
                        : "p-4 pb-20 w-full sm:w-[600px] md:w-[600px] md818:w-[700px] lg:w-[800px] lg1170:w-[900px] xl:w-[1000px] 2xl:w-[1200px]  "
                }>
                <div className="relative z-50 ">
                    {modalAvanzado && (
                        <VariablePrices
                            setModalAvanzado={setModalAvanzado}
                            onboarding={onboarding}
                            setRegresar={setRegresar}
                        />
                    )}
                </div>
                <div className="relative z-50 ">
                    {modalDisponibilidad && (
                        <DaysAvailableBoats
                            setModalDisponibilidad={setModalDisponibilidad}
                            onboarding={onboarding}
                            setOnboarding={setOnboarding}
                            producto={producto}
                            tipo={tipo}
                            setRegresar={setRegresar}
                        />
                    )}
                </div>
                <div className="relative z-50 ">
                    {modal && (
                        <ModalCalendarBoats
                            duracion={duracionSelect}
                            fechas={fechas}
                            setModal={setModal}
                            onboarding={onboarding}
                            valorDia={valorDia}
                        />
                    )}
                </div>

                <div
                    className={
                        !modalAvanzado && !modalDisponibilidad ? "" : "hidden"
                    }>
                    <p className=" text-4xl font-bold mt-4 text-[#3C3C3C]  px-6 pt-6 w-full">
                        {onboarding.nombre}
                    </p>
                    <div className="grid grid-cols-1 px-6 mx-auto mt-6 justify-items-center md818:justify-items-stretch md818:grid-cols-2">
                        <div className="flex mb-5 md818:mb-0">
                            <button
                                onClick={() => setModalCalendario(false)}
                                className={`${
                                    true
                                        ? "p-3 text-blue-500 border border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                        : "p-3 px-6 w-[156px]  text-white bg-blue-500 border rounded-lg border-blue-500"
                                }`}>
                                Detalles
                            </button>
                            <button
                                className={`${
                                    false
                                        ? "p-3 ml-2 text-blue-500 border border-blue-500 px-6 w-[156px] rounded-lg hover:bg-white hover:text-blue-500"
                                        : "p-3 px-6 w-[156px] text-white bg-blue-500 border rounded-lg ml-2 border-blue-500"
                                }`}>
                                Disponibilidad
                            </button>
                        </div>

                        <div
                            className={
                                onboarding.tipo_producto === "casa"
                                    ? "flex justify-end text-center "
                                    : "hidden"
                            }>
                            <p
                                onClick={() => (
                                    setModalAvanzado(true), setRegresar(true)
                                )}
                                className="py-3  text-blue-500 border border-blue-500  w-[156px] rounded-lg hover:bg-white hover:text-blue-500 cursor-pointer">
                                Opciones avanzadas
                            </p>
                            <p
                                onClick={() => (
                                    setModalDisponibilidad(true),
                                    setRegresar(true)
                                )}
                                className="py-3 ml-2 text-blue-500 border border-blue-500  w-[156px] rounded-lg hover:bg-white hover:text-blue-500 cursor-pointer">
                                Dias no disponibles
                            </p>
                        </div>
                        <div
                            className={
                                onboarding.tipo_producto !== "embarcacion"
                                    ? "hidden"
                                    : "relative ml-5 flex justify-end"
                            }>
                            <p
                                onClick={() => setModalDisponibilidad(true)}
                                className="p-3 ml-2 text-blue-500 border border-blue-500  w-[156px] rounded-lg hover:bg-white hover:text-blue-500 cursor-pointer mr-2">
                                Dias no disponibles
                            </p>
                            <button
                                onClick={() =>
                                    setModalHabilitado(!modalHabilitado)
                                }
                                className={
                                    duraciones.length === 1
                                        ? "hidden"
                                        : "w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-700 border-2 border-blue-500 rounded-lg group hover:bg-blue-700 hover:text-white"
                                }>
                                <svg
                                    className="w-6 h-6 text-blue-500 xs:w-8 xs:h-8 group-hover:text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                            </button>
                            <div
                                className={
                                    modalHabilitado
                                        ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                        : "hidden"
                                }>
                                <div
                                    className={
                                        modalHabilitado
                                            ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                            : "hidden"
                                    }>
                                    {duraciones?.map((duracion, i) => {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => {
                                                    setDuracionSelect(duracion);

                                                    setModalHabilitado(false);
                                                }}
                                                className={
                                                    duracion === duracionSelect
                                                        ? "hidden"
                                                        : "w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-2"
                                                }>
                                                <p>{duracion.duracion}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={
                        onboarding.tipo_producto === "casa" ||
                        modalDisponibilidad
                            ? "hidden"
                            : "text-center mt-7 font-semibold text-lg lg:text-3xl md:text-2xl px-6 mx-auto py-2   bg-[#053465] text-white rounded-lg "
                    }>
                    {duracionSelect?.duracion}
                </div>
                <div className="px-6 mx-auto mb-20 overflow-hidden ">
                    <div
                        className={
                            !modalAvanzado && !modalDisponibilidad
                                ? " w-[101.1%]  mx-auto mt-10 font-bold  text-[8px]  md:text-sm lg:text-lg  px-2 "
                                : "hidden"
                        }>
                        {duracionSelect !== "" && spinner && (
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                titleFormat={{ year: "numeric", month: "long" }}
                                initialView="dayGridMonth"
                                locale={"es"}
                                events={conteo}
                                dateClick={handleDateClick}
                                eventClick={(info) =>
                                    handleEventClick(info.event._def)
                                }
                            />
                        )}
                        {onboarding.tipo_producto === "casa" && spinner && (
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                titleFormat={{ year: "numeric", month: "long" }}
                                initialView="dayGridMonth"
                                locale={"es"}
                                events={conteo}
                                dateClick={handleDateClick}
                                eventClick={(info) =>
                                    handleEventClick(info.event._def)
                                }
                            />
                        )}

                        {!spinner && <Spinner width={20} />}
                        <hr className="w-full pl-4 mt-12 border-white" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PriceCalendar;
