import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

const ModalDaysAvailableBoats = ({
    modalVisible,
    setModalVisible,
    onboarding,

    fakeRefresh,
    setFakeRefresh,
}) => {
    const authUser = useSelector((state) => state.authReducer);
    registerLocale("es", es);
    const allDates = {
        months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ],
        days: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo",
        ],
    };
    const [input, setInput] = useState({
        tipoVariante: "Rango de Fechas",
        fechaHasta: "",
        fechaDesde: "",
        mesInicio: "",
        mesFin: "",
        diaInicio: "",
        diaFin: "",
    });

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleClose = () => {
        setInput({
            name: "",
            description: "",
            price: 0,
        });
        setModalVisible(!modalVisible);
    };

    const handleSave = () => {
        if (onboarding.tipo_producto === "embarcacion") {
            if (
                input.tipoVariante === "Rango de Fechas" &&
                input.fechaDesde !== "" &&
                input.fechaHasta !== ""
            ) {
                const payload = {
                    id_embarcacion: onboarding.id,

                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else if (
                input.tipoVariante === "Rango de Meses" &&
                input.mesInicio !== "" &&
                input.mesFin !== ""
            ) {
                const payload = {
                    id_embarcacion: onboarding.id,

                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else if (
                input.tipoVariante === "Rango de Dias" &&
                input.diaInicio !== "" &&
                input.diaFin !== ""
            ) {
                const payload = {
                    id_embarcacion: onboarding.id,

                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Por favor, complete todos los campos",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
        if (onboarding.tipo_producto === "casa") {
            if (
                input.tipoVariante === "Rango de Fechas" &&
                input.fechaDesde !== "" &&
                input.fechaHasta !== ""
            ) {
                const payload = {
                    id_producto: onboarding.id,
                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else if (
                input.tipoVariante === "Rango de Meses" &&
                input.mesInicio !== "" &&
                input.mesFin !== ""
            ) {
                const payload = {
                    id_producto: onboarding.id,
                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else if (
                input.tipoVariante === "Rango de Dias" &&
                input.diaInicio !== "" &&
                input.diaFin !== ""
            ) {
                const payload = {
                    id_producto: onboarding.id,
                    tipo_variante: input.tipoVariante,
                    fecha_inicio: input.fechaDesde,
                    fecha_final: input.fechaHasta,
                    mes_inicio: input.mesInicio,
                    mes_final: input.mesFin,
                    dia_inicio: input.diaInicio,
                    dia_final: input.diaFin,
                };
                updateProduct(payload);
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Por favor, complete todos los campos",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
    };

    const updateProduct = async (payload) => {
        if (onboarding.tipo_producto === "embarcacion") {
            let json = await axios.post(
                `${process.env.REACT_APP_PROXY}/embarcacionDisponiblidad`,
                { ...payload, id_usuario: authUser.id_usuario },
                { headers }
            );

            if (json.data.message === "Registro Guardado") {
                Swal.fire({
                    title: "Creado",
                    text: "Creado correctamente",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setFakeRefresh(!fakeRefresh);

                setModalVisible(!modalVisible);
            } else if (json.data.error) {
                Swal.fire({
                    title: "Error",
                    text: `${json.data.error}`,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
        if (onboarding.tipo_producto === "casa") {
            let json = await axios.post(
                `${process.env.REACT_APP_PROXY}/productoDisponiblidad`,
                { ...payload, id_usuario: authUser.id_usuario },
                { headers }
            );

            if (json.data.message === "Registro Guardado") {
                Swal.fire({
                    title: "Creado",
                    text: "Creado correctamente",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setFakeRefresh(!fakeRefresh);

                setModalVisible(!modalVisible);
            } else if (json.data.error) {
                Swal.fire({
                    title: "Error",
                    text: `${json.data.error}`,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        }
    };

    return (
        <div
            className={`relative items-center justify-center ${
                modalVisible ? "flex" : "hidden"
            }`}>
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-80 sticky-0">
                <div className="flex items-center justify-center px-4 py-8 2xl:container 2xl:mx-auto sm:py-48">
                    <div className="relative flex flex-col items-center  px-2 py-12 bg-white rounded-lg w-[350px]  dark:bg-white ">
                        <button
                            onClick={handleClose}
                            className="absolute text-gray-800 dark:text-gray-400 top-8 right-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                            <svg
                                className="rounded-full hover:bg-gray-600 hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18 6L6 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6 6L18 18"
                                    stroke="currentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <div className="w-11/12 my-3 text-left">
                            <header className="mb-3  text-[#3C3C3C]  text-base  font-bold ">
                                Nueva disponibilidad
                            </header>
                        </div>
                        {/* <div className="w-11/12 text-left ">
                            <header className="mb-3  text-[#3C3C3C]  text-base ">
                                Tipo variante
                            </header>
                        </div>
                        <div className="w-11/12  rounded-lg border border-[#E9E9E9]">
                            <select
                                name="tipoVariante"
                                className="w-full my-3  border-none outline-none focus:outline-none placeholder:text-[#3C3C3C] text-[#3C3C3C] bg-white"
                                value={input.tipoVariante}
                                onClick={(e) =>
                                    setInput({
                                        tipoVariante: e.target.value,
                                        fechaHasta: "",
                                        fechaDesde: "",
                                        mesInicio: "",
                                        mesFin: "",
                                        diaInicio: "",
                                        diaFin: "",
                                    })
                                }
                                onChange={handleInputChange}>
                                <option>Rango de Fechas</option>
                                <option>Rango de Meses</option>

                                <option>Rango de Dias</option>
                            </select>
                        </div> */}
                        {input.tipoVariante === "Rango de Fechas" && (
                            <div className="flex flex-col mt-1 text-[#3C3C3C]">
                                <div className="flex mx-auto">
                                    <div className="mx-2 ">
                                        <label className="p-2 text-base font-semibold ">
                                            Desde:
                                        </label>
                                        {/* <input
                                            type="date"
                                            name="fechaDesde"
                                            className="p-2 bg-white rounded-lg"
                                            onChange={handleInputChange}
                                        /> */}
                                        <DatePicker
                                            className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaDesde"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                input.fechaDesde !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              input.fechaDesde
                                                          )[2]
                                                      )
                                                    : input.fechaDesde
                                            }
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    fechaDesde: `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="p-2 text-base font-semibold ">
                                            Hasta:
                                        </label>
                                        {/* <input
                                            type="date"
                                            name="fechaHasta"
                                            className="p-2 bg-white rounded-lg"
                                            onChange={handleInputChange}
                                        /> */}
                                        <DatePicker
                                            className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                            placeholderText="Seleccionar fecha"
                                            locale="es"
                                            autoComplete="off"
                                            name="fechaHasta"
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                input.fechaHasta !== ""
                                                    ? new Date(
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[0],
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[1] - 1,
                                                          dividirFechaDatePicker(
                                                              input.fechaHasta
                                                          )[2]
                                                      )
                                                    : input.fechaHasta
                                            }
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    fechaHasta: `${e.getFullYear()}-${`${
                                                        e.getMonth() + 1
                                                    }`.padStart(
                                                        2,
                                                        "0"
                                                    )}-${`${e.getDate()}`.padStart(
                                                        2,
                                                        "0"
                                                    )}`,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {input.tipoVariante === "Rango de Meses" && (
                            <div className="flex flex-col mt-1 text-[#3C3C3C]">
                                <div className="flex mx-auto">
                                    <div className="mx-2 ">
                                        <label className="p-2 text-base font-semibold ">
                                            Desde:
                                        </label>
                                        <select
                                            name="mesInicio"
                                            value={input.mes}
                                            className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white"
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.months.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mx-2 ">
                                        <label className="p-2 text-base font-semibold ">
                                            Hasta:
                                        </label>
                                        <select
                                            name="mesFin"
                                            value={input.mes}
                                            className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white"
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.months.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        {input.tipoVariante === "Rango de Dias" && (
                            <div className="flex flex-col mt-3 text-[#3C3C3C]">
                                <div className="flex mx-auto">
                                    <div className="ml-3 -mt-1.5">
                                        <label className="p-2 text-base font-semibold ">
                                            Desde:
                                        </label>

                                        <select
                                            name="diaInicio"
                                            className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white"
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.days.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="ml-3 -mt-1.5">
                                        <label className="p-2 text-base font-semibold ">
                                            Hasta:
                                        </label>
                                        <select
                                            name="diaFin"
                                            className="w-32 p-2 mt-1.5 rounded-lg border border-[#E9E9E9] md:w-auto bg-white"
                                            onChange={handleInputChange}>
                                            <option value="">Seleccione</option>
                                            {allDates.days.map((month) => (
                                                <option key={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <button
                            className="w-11/12 py-3 mt-4 text-center text-white border border-blue-400 rounded-md bg-blue-bluecorner hover:bg-white hover:text-blue-bluecorner disabled:bg-blue-400"
                            onClick={(e) => handleSave(e)}>
                            Añadir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDaysAvailableBoats;
