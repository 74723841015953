import React, { useState } from "react";
import { useDispatch } from "react-redux";

const SearchBar = ({
    getAllDisponibilidadEmbarcaciones,
    getAllDisponibilidadCasas,
    pagina,
    select,
    setNombre,
    id,
    tipo,
}) => {
    const [input, setinput] = useState("");

    const handleInputChange = (e) => {
        setinput(e.target.value);
        setNombre(e.target.value);

        setTimeout(() => {
            if (e.target.value.length > 0) {
                if (tipo === "embarcaciones") {
                    getAllDisponibilidadEmbarcaciones({
                        pagina: 1,
                        id_duracion: select.id,
                        nombre: e.target.value,
                        id_usuario: id,
                    });
                } else {
                    getAllDisponibilidadCasas({
                        pagina: pagina,
                        nombre: e.target.value,
                        id_usuario: id,
                    });
                }
            } else {
                if (tipo === "embarcaciones") {
                    getAllDisponibilidadEmbarcaciones({
                        pagina: 1,
                        id_duracion: select.id,
                        nombre: e.target.value,
                        id_usuario: id,
                    });
                } else {
                    getAllDisponibilidadCasas({
                        pagina: pagina,
                        nombre: e.target.value,
                        id_usuario: id,
                    });
                }
            }
        }, 2000);
    };

    return (
        <div className="flex w-64 p-1 border rounded appearance-none xs:w-72 lg:w-80 left-2 sm:max-w-screen-sm text-stone-900">
            <button className="flex items-center justify-center px-1">
                <svg
                    className="w-6 h-6 text-blue-bluecorner"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                </svg>
            </button>
            <input
                onBlur={() => setinput("")}
                value={input}
                type="text"
                onChange={handleInputChange}
                className="w-full px-1 py-2 bg-transparent outline-none appearance-none placeholder:text-[#3C3C3C]"
                placeholder="Buscar"
            />
        </div>
    );
};

export default SearchBar;
